body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

/* Header Styles */

header {
  background-color: #1b1b1b;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sticky-header {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 60px;
}

@media (max-width: 767px) {
  .sticky-header {
    height: 40px;
  }
}

@media (max-width: 479px) {
  .sticky-header {
    height: 30px;
  }
}

@media (min-width: 768px) {
  .sticky-header {
    height: 30px;
  }
}

@media (min-width: 992px) {
  .sticky-header {
    height: 20px;
  }
}

@media (min-width: 1200px) {
  .sticky-header {
    height: 10px;
  }
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-right: 20px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #8da5b8;
}

/* Main Styles */

#title {
  background-image: url(CharcoalBackground.jpg);
  background-position: center;
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#title h1 {
  color: #fff;
  text-align: center;
  font-size: 48px;
  text-shadow: 1px 1px 0 #333;
}

#missionstatement {
  padding: 50px;
}

#missionstatement p {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
}

/* Footer Styles */

.sticky-footer {
  position: fixed;
  background-color: #1b1b1b;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  bottom: 0;
  width: 100%;
}